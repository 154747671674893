import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useLayoutEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import styled from 'styled-components/native';

import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { Divider } from '~/components/divider';
import { LoadingContent } from '~/components/loader';
import { PreviousScreenHeader } from '~/components/navigation/previous-screen-header';
import { ScreenContainer } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useCalendarEventList } from '~/hooks/calendar-event-list';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { CalendarEvent } from '~/types';

import { UpcomingAppointments } from '../home/upcoming-appointments';
import { NoContentContainer } from '~/components/no-content-container';

type CalendarEventsNavigation = StackNavigationProp<MainNavigatorParamList, 'calendar-events'>;

export function CalendarEvents() {
    const { calendarEvents, loading, error } = useCalendarEventList();
    const { calendarEvents: pastCalendarEvents, loading: pastLoading, error: pastError } = useCalendarEventList(true);
    useErrorHandler(error);
    useErrorHandler(pastError);

    const { goBack, navigate, setOptions } = useNavigation<CalendarEventsNavigation>();
    const back = useCallback(() => goBack(), [goBack]);
    const { formatMessage } = useIntl();

    const webComponent = useBreakpointValue({
        base: false,
        md: true
    });

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.calendar-events.title') }));

    const handleOpenCalendarEvent = useCallback(
        (calendarEvent: CalendarEvent) => navigate('appointment', { calendarEventId: calendarEvent.id }),
        [navigate]
    );

    const hasAnyAppointments = (calendarEvents?.length ?? 0) + (pastCalendarEvents?.length ?? 0) > 0;
    const isLoading = loading || pastLoading;

    return (
        <ScreenContainer>
            <ContentHorizontalMargins contentWidth="midsize" includePadding={webComponent}>
                <Flex flexDir="column" grow={1} paddingTop={4} paddingLeft={4} paddingRight={4}>
                    {webComponent && <PreviousScreenHeader goBack={back} />}
                    {loading && <LoadingContent />}
                    {calendarEvents && calendarEvents.length > 0 && (
                        <Flex>
                            {webComponent && <Divider leftInset={0} before={0} after={Spacing.MEDIUM} />}
                            <MessageHeaderContainer>
                                <Text.HEADER_3>
                                    <FormattedMessage id="appointments.title" />
                                </Text.HEADER_3>
                            </MessageHeaderContainer>
                            <UpcomingAppointments
                                handleOpenCalendarEvent={handleOpenCalendarEvent}
                                calendarEvents={calendarEvents}
                            />
                        </Flex>
                    )}
                    {pastLoading && <LoadingContent />}
                    {pastCalendarEvents && pastCalendarEvents.length > 0 && (
                        <Flex>
                            {webComponent && <Divider leftInset={0} before={0} after={Spacing.MEDIUM} />}
                            <MessageHeaderContainer>
                                <Text.HEADER_3>
                                    <FormattedMessage id="appointments.past" />
                                </Text.HEADER_3>
                            </MessageHeaderContainer>
                            <UpcomingAppointments calendarEvents={pastCalendarEvents} isDisabled />
                        </Flex>
                    )}
                    {!isLoading && !hasAnyAppointments && (
                        <Flex>
                            <NoContentContainer>
                                <Spacing.Vertical.SMALL />
                                <Text.PARAGRAPH_1>
                                    <FormattedMessage id="profile.calendar-events.none" />
                                </Text.PARAGRAPH_1>
                            </NoContentContainer>
                        </Flex>
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </ScreenContainer>
    );
}

const MessageHeaderContainer = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${Spacing.MEDIUM}px;
`;
