import { Flex } from 'native-base';
import { useCallback } from 'react';

import { useIntl } from '~/contexts/intl';

import { Button } from '../button';
import { ChevronLeftIcon } from '../icon';

type PreviousScreenHeaderProps = {
    goBack: () => void;
};

export const PreviousScreenHeader: React.FC<PreviousScreenHeaderProps> = props => {
    /* Breadcrumbs for Web, temporarily 'go back' */
    const { goBack } = props;
    // const { goBack, navigate, setOptions } = useNavigation<CalendarEventsNavigation>();
    const back = useCallback(() => goBack(), [goBack]);
    const { formatMessage } = useIntl();

    return (
        <Flex paddingBottom="4">
            <Button
                leftIcon={ChevronLeftIcon}
                size="small"
                type="text"
                label={formatMessage('goBack').toUpperCase()}
                style={{ maxWidth: 140 }}
                onPress={() => back()}
            />
        </Flex>
    );
};
