import Constants from 'expo-constants';
import React, { useState, PropsWithChildren, useMemo, useContext } from 'react';

import { config } from '~/app/config';

export type AppEndpoint = 'development' | 'dev' | 'staging' | 'production';

export type AppEndpointConfig = {
    host: string;
    http: 'http' | 'https';
    ws: 'ws' | 'wss';
    sentry?: {
        dsn: string;
    };
    helttiViewUrl: string;
};

export type AppConfig = {
    [key in AppEndpoint]: AppEndpointConfig;
};

export type AppConfigContextType = {
    endpoint: AppEndpoint;
    config: AppEndpointConfig;
    setEndpoint: (endpoint: AppEndpoint) => void;
};

export const AppConfigContext = React.createContext<AppConfigContextType | undefined>(undefined);

const AppConfigContextProvider = ({ children }: PropsWithChildren<object>) => {
    const [endpoint, setEndpoint] = useState<AppEndpoint>(Constants.expoConfig?.extra?.profile ?? 'development');

    const value = useMemo(
        () => ({
            endpoint,
            config: config[endpoint],
            setEndpoint: (newEndpoint: AppEndpoint) => setEndpoint(newEndpoint)
        }),
        [endpoint, setEndpoint]
    );

    return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};

export const AppConfigConsumer = AppConfigContext.Consumer;
export const AppConfigProvider = AppConfigContextProvider;

export const useAppConfig = () => {
    const context = useContext(AppConfigContext);
    if (!context) {
        throw Error('Cannot use app config context until it has been defined');
    }
    return context;
};
