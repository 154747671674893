import { Flex } from 'native-base';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { Button } from '~/components/button';
import { Color } from '~/components/color';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';

export type ListAccessoryRenderFunc = (pressed: boolean) => ReactNode;

export type ListItemProps = {
    title?: string;
    description?: string;
    appearance?: 'default' | 'compact';
    accessory?: ListAccessoryRenderFunc;
    onPress: () => void | Promise<void>;
    children?: ReactNode;
    expandableDescription?: boolean;
    maxCharacters?: number;
};

const ListItemContainer = styled(Pressable)<{ appearance?: 'default' | 'compact' }>`
    flex-direction: row;
    align-items: center;
`;

const ListItemTextContainer = styled.View`
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px;
`;

export const ListItemTitle = styled(Text.BUTTON_LABEL).attrs({ after: 4, numberOfLines: 1 })`
    color: ${Color.HEADER};
`;

export const ListItemDescription = styled(Text.P1).attrs({ muted: true })`
    color: ${Color.TEXT_SECONDARY};
`;

export const ListItemPane = styled.Pressable`
    padding: 16px 16px;
    background-color: ${Color.BACKGROUND_DEFAULT};
`;

export const ListItemPaneContent = styled.View`
    padding: 8px 18px 2px;
`;

export const ListItemDivider = styled.View`
    border-color: ${Color.LINE_DEFAULT};
    border-bottom-width: ${StyleSheet.hairlineWidth}px;
    margin-left: ${Spacing.MEDIUM}px;
`;

const AccessoryContainer = styled.View`
    width: 40px;
    align-items: center;
    margin: 8px;
`;

export const ListItem: FunctionComponent<ListItemProps> = props => {
    const { formatMessage } = useIntl();
    const {
        title,
        description,
        appearance,
        accessory,
        onPress,
        children,
        expandableDescription = false,
        maxCharacters = 120
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderDescription = () => {
        if (!description?.length) return null;

        if (!expandableDescription) {
            return <ListItemDescription>{description}</ListItemDescription>;
        }

        const shouldTruncate = description.length > maxCharacters && !isExpanded;
        const displayText = shouldTruncate ? `${description.slice(0, maxCharacters)}...` : description;

        return (
            <>
                <ListItemDescription>{displayText}</ListItemDescription>
                {description.length > maxCharacters && (
                    <Flex ml={-5}>
                        <Pressable accessibilityRole="button" onPress={event => event.stopPropagation()}>
                            <Button
                                type="text"
                                label={isExpanded ? formatMessage('showLess') : formatMessage('showMore')}
                                onPress={toggleExpand}
                                alignCenter={false}
                                size="small"
                            />
                        </Pressable>
                    </Flex>
                )}
            </>
        );
    };

    return (
        <ListItemContainer onPress={onPress} appearance={appearance}>
            {({ pressed }: { pressed: boolean }) =>
                children ?? (
                    <>
                        <ListItemTextContainer>
                            {title?.length ? <ListItemTitle numberOfLines={1}>{title}</ListItemTitle> : null}
                            {renderDescription()}
                        </ListItemTextContainer>
                        {accessory && <AccessoryContainer>{accessory(pressed)}</AccessoryContainer>}
                    </>
                )
            }
        </ListItemContainer>
    );
};
